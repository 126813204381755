import vm from "../main";
import axios from "axios";

export default class StyleJsonService {
  static async getStyleJson() {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`https://pointr-maps.azureedge.net/style/8.16/light_PCD.json`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }
}
