import axios from "axios";

export default class MapSearchService {
  static async getSuggestions(searchText, count, lat = 0, lng = 0) {
    let response;
    try {
      response = await axios(
        `${process.env.VUE_APP_HERE_BASE_URL}/autosuggest?at=${lat},${lng}&limit=${count}&q=${searchText}&apiKey=${process.env.VUE_APP_HERE_API_KEY}`
      );
    } catch (error) {
      console.error(error);
    }

    return response;
  }
}
