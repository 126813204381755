import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import generateColors from "../assets/colors";
import { setupSystemThemeListener, isDarkTheme } from "../helpers/ThemeHelpers";

import PtrIcon from "@/components/shared/PtrIcon";
Vue.use(Vuetify);

const customColors = generateColors(isDarkTheme(localStorage.getItem("theme")));

customColors["background-darken3"] = customColors.background.darken3;
customColors["background-lighten4"] = customColors.background.lighten4;
customColors["background-lighten5"] = customColors.background.lighten5;
customColors["foreground-lighten3"] = customColors.foreground.lighten5;
customColors["foreground-lighten5"] = customColors.foreground.lighten5;
customColors["foreground-darken1"] = customColors.foreground.darken1;
customColors["foreground-darken2"] = customColors.foreground.darken2;
customColors["foreground-darken3"] = customColors.foreground.darken3;
customColors["foreground-darken5"] = customColors.foreground.darken5;
customColors["theme-lighten5"] = customColors.theme.lighten5;
customColors["theme-lighten3"] = customColors.theme.lighten3;
customColors["theme-darken2"] = customColors.theme.darken3;
customColors["theme-darken3"] = customColors.theme.darken3;
customColors["theme-darken4"] = customColors.theme.darken4;
customColors["theme-darken5"] = customColors.theme.darken5;
customColors["danger-darken1"] = customColors.danger.darken1;

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: isDarkTheme(localStorage.getItem("theme")),
    themes: {
      light: customColors,
      dark: customColors
    }
  },
  icons: {
    values: {
      dataTableSort: {
        component: PtrIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          icon: ["arrows-down"]
        }
      }
    }
  }
});

setupSystemThemeListener(vuetify);

export default vuetify;
