<template>
  <div
    v-if="descendingLevels.length > 1"
    id="map-ui-levels"
    :class="{ 'is-expanded': isShownPathLevelSelectorExpanded }"
  >
    <v-btn
      v-for="(levelIndex, i) in descendingLevels"
      :key="i"
      color="white"
      class="v-btn--square map-ui-level"
      :class="{ 'current-level': levelIndex == shownPathLevel }"
      @click="levelSelected(levelIndex)"
    >
      <PtrIcon v-if="!isShownPathLevelSelectorExpanded && levelIndex !== descendingLevels[0]" icon="caret-up" />
      {{ levelIndex }}
      <PtrIcon
        v-if="!isShownPathLevelSelectorExpanded && levelIndex !== descendingLevels[descendingLevels.length - 1]"
        icon="caret-down"
      />
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: {
    PtrIcon
  },

  computed: {
    ...mapState("CONTENT", ["buildings", "graphs", "shownPathLevel", "isShownPathLevelSelectorExpanded"]),
    descendingLevels() {
      let indoorLevels = this.graphs
        .filter(
          (feature) => feature.properties.typeCode === "building-entrance-exit" && feature.properties?.lvl !== undefined
        )
        .map((feature) => feature.properties.lvl);
      let lowestLevel = this.buildings
        ?.map((building) => building.levels)
        ?.flat(Infinity)
        ?.map((level) => level?.levelIndex)
        ?.sort((a, b) => a - b)?.[0];
      if (indoorLevels && indoorLevels?.length > 0) {
        let levelSet = new Set(indoorLevels);
        let orderedLevels = Array.from(levelSet).sort((a, b) => b - a);
        return orderedLevels;
      }
      return [lowestLevel];
    }
  },

  watch: {
    descendingLevels(oldValue, newValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue) || this.shownPathLevel === undefined) {
        this.$store.commit("CONTENT/SHOWN_PATH_LEVEL", this.descendingLevels?.[this.descendingLevels?.length - 1]);
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener("click", this.levelSelectorHandler);
  },

  created() {
    document.addEventListener("click", this.levelSelectorHandler);
  },

  methods: {
    levelSelected(levelIndex) {
      this.$store.dispatch("CONTENT/SET_SHOWN_PATH_LEVEL", levelIndex);
    },
    levelSelectorHandler(e) {
      if (this.isShownPathLevelSelectorExpanded && !e.target.closest("#map-ui-levels")) {
        this.$store.commit("CONTENT/IS_SHOWN_PATH_LEVEL_SELECTOR_EXPANDED", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#map-ui-levels {
  display: flex;
  flex-direction: column;
  max-height: 64px;
  position: relative;
  transition: all 0.5s ease-in-out;
  overflow: hidden overlay;
  pointer-events: all;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: -8px;
  margin-left: -8px;
  overflow-y: hidden;

  &:not(.is-expanded) .map-ui-level {
    margin-top: 0 !important;
  }

  .map-ui-level:not(.current-level) {
    transition: all 0.5s ease-in-out;
    display: none;
  }

  &.is-expanded {
    max-height: 100vh;

    .map-ui-level {
      display: flex !important;
    }

    .current-level {
      background: var(--v-primary-lighten4) !important;
      color: var(--v-primary-base) !important;
    }
  }

  .caret-up,
  .caret-down {
    display: none;
    position: absolute;
    left: 18px;
    padding: 0 !important;
  }

  .caret-up {
    top: -10px;
  }

  .caret-down {
    bottom: -10px;
  }

  .map-ui-level.current-level {
    position: sticky;
    top: 0;
    left: 0;

    .caret-up,
    .caret-down {
      display: flex;
    }
  }
}
</style>
