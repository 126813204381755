<template>
  <div>
    <PtrSearch
      class="pointr-search"
      :search-results="searchResultsAsText"
      :search-results-details="searchResultsDetails"
      :auto-search-length="3"
      :is-loading="isLoading"
      @searched="search"
      @cleared="cleared"
      @result-selected="resultSelected"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import maplibregl from "maplibre-gl";
import PtrSearch from "@/components/shared/PtrSearch.vue";
import MapHelpers from "@/helpers/MapHelpers";
import ToastHelpers from "@/helpers/ToastHelpers";
import MapSearchService from "@/services/MapSearchService";

export default {
  name: "MapSearch",
  components: { PtrSearch },

  data: () => ({
    predictions: undefined,
    results: null,
    isLoading: false,
    timeoutInstance: undefined,
    placeMarker: undefined
  }),

  computed: {
    ...mapState("MAP", ["map"]),
    searchResultsAsText() {
      const allResults = this.predictions?.map((result) => result.title || result.address.label);
      return [...new Set(allResults)];
    },
    searchResultsDetails() {
      const resultDetails = this.predictions?.map((detail) => detail?.address?.label);
      return [...new Set(resultDetails)];
    }
  },

  beforeDestroy() {
    this.placeMarker?.remove();
    this.placeMarker = undefined;
  },

  methods: {
    search(searchInput) {
      this.isLoading = true;
      this.predictions = undefined;
      const timeoutDuration = 250;
      clearTimeout(this.timeoutInstance);
      this.timeoutInstance = setTimeout(async () => {
        if (typeof searchInput !== "string" || searchInput.length === 0) {
          return;
        }
        const RESULT_COUNT = 15;
        try {
          let center = this.map.getCenter();
          const res = await MapSearchService.getSuggestions(searchInput, RESULT_COUNT, center?.lat, center?.lng);
          let searchResults = res?.data?.items?.filter((item) => item?.address?.label);

          this.predictions = searchResults?.slice(0, 7);
          this.results = searchResults?.slice(0, 7);
        } catch (err) {
          console.log(err);
        } finally {
          this.isLoading = false;
        }
      }, timeoutDuration);
    },
    cleared() {
      this.predictions = undefined;
      this.placeMarker?.remove();
      this.placeMarker = undefined;
    },
    async resultSelected(selectedResultText) {
      const selectedResult = this.results.find((item) => item.title === selectedResultText);

      if (selectedResult) {
        this.addMarker([selectedResult.position.lng, selectedResult.position.lat]);
        MapHelpers.jumpToPoint([selectedResult.position.lng, selectedResult.position.lat], { zoom: 17 });
      } else {
        ToastHelpers.createErrorToast(this.$t(`contents.sites.search-result-failed`));
      }
    },
    addMarker(coordinates) {
      this.placeMarker = new maplibregl.Marker().setLngLat([coordinates[0], coordinates[1]]).addTo(this.map);
    }
  }
};
</script>

<style lang="scss" scoped>
.pointr-search {
  pointer-events: auto;

  .v-input {
    box-shadow: 0px 4px 8px #17191c29 !important;
    border: none !important;

    &:hover {
      box-shadow: 0px 4px 8px #17191c52 !important;
    }
  }
}
</style>
